@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro);
/** Proxima Nova **/



/*@font-face {*/
    /*font-family: 'proxima_nova_altblack';*/
    /*!*src: url('Mark Simonson - Proxima Nova Alt Black-webfont.eot');*!*/
    /*!*src: url('Mark Simonson - Proxima Nova Alt Black-webfont.eot?#iefix') format('embedded-opentype'),*!*/
         /*url('Mark Simonson - Proxima Nova Alt Black-webfont.woff2') format('woff2'),*/
         /*url('Mark Simonson - Proxima Nova Alt Black-webfont.woff') format('woff'),*/
         /*url('Mark Simonson - Proxima Nova Alt Black-webfont.ttf') format('truetype'),*/
         /*url('Mark Simonson - Proxima Nova Alt Black-webfont.svg#proxima_nova_altblack') format('svg');*/
    /*font-weight: normal;*/
    /*font-style: normal;*/

/*}*/

@font-face {
    font-family: 'proxima_nova_altbold';
    src: url("/static/media/Mark Simonson - Proxima Nova Alt Bold-webfont.7a949b68.eot");
    src: url("/static/media/Mark Simonson - Proxima Nova Alt Bold-webfont.7a949b68.eot"?#iefix) format('embedded-opentype'),
         url("/static/media/Mark Simonson - Proxima Nova Alt Bold-webfont.d95e053c.woff2") format('woff2'),
         url("/static/media/Mark Simonson - Proxima Nova Alt Bold-webfont.fa0710b8.woff") format('woff'),
         url("/static/media/Mark Simonson - Proxima Nova Alt Bold-webfont.d728a3db.ttf") format('truetype'),
         url("/static/media/Mark Simonson - Proxima Nova Alt Bold-webfont.bfe2bf49.svg"#proxima_nova_altbold) format('svg');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'Proxima Nova';
    src: url("/static/media/Mark Simonson - Proxima Nova Alt Regular-webfont.e802340f.eot");
    src: url("/static/media/Mark Simonson - Proxima Nova Alt Regular-webfont.e802340f.eot"?#iefix) format('embedded-opentype'),
         
         url("/static/media/Mark Simonson - Proxima Nova Alt Regular-webfont.12ccd551.woff") format('woff'),
         url("/static/media/Mark Simonson - Proxima Nova Alt Regular-webfont.0b420bac.ttf") format('truetype'),
         url("/static/media/Mark Simonson - Proxima Nova Alt Regular-webfont.31015419.svg"#proxima_nova_altregular) format('svg');
    font-weight: normal;
    font-style: normal;
}

/* Generated by Font Squirrel (http://www.fontsquirrel.com) on April 4, 2015 */



@font-face {
    font-family: 'proxima_nova_scosfthin';
    src: url("/static/media/Mark Simonson - Proxima Nova ScOsf Thin-webfont.e2ecf5fa.eot");
    src: url("/static/media/Mark Simonson - Proxima Nova ScOsf Thin-webfont.e2ecf5fa.eot"?#iefix) format('embedded-opentype'),
         
         url("/static/media/Mark Simonson - Proxima Nova ScOsf Thin-webfont.572779bf.woff") format('woff'),
         url("/static/media/Mark Simonson - Proxima Nova ScOsf Thin-webfont.ba783194.ttf") format('truetype'),
         url("/static/media/Mark Simonson - Proxima Nova ScOsf Thin-webfont.57204409.svg"#proxima_nova_scosfthin) format('svg');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'proxima_nova_ltsemibold';
    src: url("/static/media/Mark Simonson - Proxima Nova Semibold-webfont.bbec7fc3.eot");
    src: url("/static/media/Mark Simonson - Proxima Nova Semibold-webfont.bbec7fc3.eot"?#iefix) format('embedded-opentype'),
         url("/static/media/Mark Simonson - Proxima Nova Semibold-webfont.4a2a396b.woff2") format('woff2'),
         
         url("/static/media/Mark Simonson - Proxima Nova Semibold-webfont.7bd0a4c6.ttf") format('truetype'),
         url("/static/media/Mark Simonson - Proxima Nova Semibold-webfont.6c1b0f2a.svg"#proxima_nova_ltsemibold) format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'proxima_nova_ltthin';
    src: url("/static/media/Mark Simonson - Proxima Nova Thin-webfont.e2d89c72.eot");
    src: url("/static/media/Mark Simonson - Proxima Nova Thin-webfont.e2d89c72.eot"?#iefix) format('embedded-opentype'),
         url("/static/media/Mark Simonson - Proxima Nova Thin-webfont.0e628fcf.woff2") format('woff2'),
         url("/static/media/Mark Simonson - Proxima Nova Thin-webfont.17aa4534.woff") format('woff'),
         url("/static/media/Mark Simonson - Proxima Nova Thin-webfont.532aaef1.ttf") format('truetype'),
         url("/static/media/Mark Simonson - Proxima Nova Thin-webfont.73313d22.svg"#proxima_nova_ltthin) format('svg');
    font-weight: normal;
    font-style: normal;

}

* {
    margin: 0;
    padding: 0;
    font-size: 100%;
}

body {
    font-family: 'Trebuchet MS', 'Source Sans Pro', serif;
    background-color: #FFFFFF;
    padding-top: 180px;

}

h1 {
    font-size: 30px;
    font-family: 'Trebuchet MS', 'Source Sans Pro', serif;
    color: #1d5980;
    font-weight: bold;
    padding-top: 0.5em;
    margin-left: 5%;
    margin-top: 0;
}

h2 {
    font-size: 16px;
    padding-top: 1em;
    margin-left: 5%;
    margin-top: 0;
    color: #1D5980;
}

a {
    color: inherit;
    font-size: 18px;
}

a.rounded {
    padding: 0.3em 1.2em;
    margin: 0 0.1em 0.1em 0;
    border: 0.16em solid rgba(255, 255, 255, 0);
    border-radius: 2em;
    box-sizing: border-box;
    text-decoration: none;
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 300;
    color: #FFFFFF;
    text-shadow: 0 0.04em 0.04em rgba(0, 0, 0, 0.35);
    text-align: center;
    transition: all 0.2s;
}

a.rounded:hover {
    border-color: rgba(255, 255, 255, 1);
}

hr {
    background-color: #1D5980;
    width: 60%;
    margin: 1em auto;
    height: 1px;
    border: none;
}

.centerButton-wrapper {
    margin: auto;
    text-align: center;
    padding: 1em;

}

.centerButton {
    margin: auto;
    background-color: #58130E;
}

@media all and (max-width: 30em) {
    a.resp {
        display: block;
        margin: 0.2em auto;
    }
}

.header {
    background-color: #1D5980;
    box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, .1);
    position: fixed;
    width: 100%;
    z-index: 3;
    color: white;
    font-size: 20px;
    top: 116px;
}

.header ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
    overflow: hidden;
    background-color: #1D5980;
}

.header li a {
    display: block;
    text-decoration: none;
    color: inherit;
    padding: 7px
}

.header li a:hover,
.header .menu-btn:hover {
    background-color: #EE2748;
}

/* menu */

.header .menu {
    clear: both;
    max-height: 0;
    transition: max-height .2s ease-out;
}

/* menu icon */

.header .menu-icon {
    cursor: pointer;
    display: inline-block;
    float: right;
    padding: 28px 20px;
    position: relative;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
}

.header .menu-icon .navicon {
    background: #fff;
    display: block;
    height: 2px;
    position: relative;
    transition: background .2s ease-out;
    width: 18px;
}

.header .menu-icon .navicon:before,
.header .menu-icon .navicon:after {
    background: #fff;
    content: '';
    display: block;
    height: 100%;
    position: absolute;
    transition: all .2s ease-out;
    width: 100%;
}

.header .menu-icon .navicon:before {
    top: 5px;
}

.header .menu-icon .navicon:after {
    top: -5px;
}

/* menu btn */

.header .menu-btn {
    display: none;
}

.header .menu-btn:checked ~ .menu {
    max-height: 240px;
}

.header .menu-btn:checked ~ .menu-icon .navicon {
    background: transparent;
}

.header .menu-btn:checked ~ .menu-icon .navicon:before {
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
}

.header .menu-btn:checked ~ .menu-icon .navicon:after {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
}

.header .menu-btn:checked ~ .menu-icon:not(.steps) .navicon:before,
.header .menu-btn:checked ~ .menu-icon:not(.steps) .navicon:after {
    top: 0;
}

/* 48em = 768px */

@media (min-width: 800px) {
    .header li {
        float: left;
        justify-content: space-around;
    }

    .header li a {
        padding: 13px 25px;
    }

    .header .menu {
        clear: none;
        justify-content: space-around;
        max-height: none;
        max-width: 800px;
        margin: auto;
    }

    .header .menu-icon {
        display: none;
    }
}

/*dropdown menu bar*/
.header ul ul {
    display: none;
    position: absolute;
    top: 100%;
    background: #1D5980;
    padding: 0
}

.header ul ul li {
    float: none;
    width: 200px
}

.header ul ul a {
    line-height: 120%;
    padding: 10px 15px
}

.header ul li:hover > ul {
    display: block
}

.login-logo {
    padding: 2em 0 0.5em 0;
}

.logo-header {

}

/* Page content */
.content {
    padding: 16px;
}

.logo {
    display: inline;
    vertical-align: top;
    left: 10px;
    width: 90px;
    height: 65px;
    margin-right: 20px;
    fill: white !important;
    padding-left: 1em;
}

/*Landing*/
.landing-wrapper {
    width: 100%;
    /*padding-top: 60px;*/
    margin: 0;
    display: inline-block;
    background-repeat: no-repeat;
    background-size: cover;
}

.landing-wrapper h1 {
    color: white;
    width: 40%;
    font-weight: lighter;
    float: left;
    padding-top: 2em;
}

.landing-slider {
    color: white;
    float: left;
    height: 400px;
    margin-left: 5%;
    width: 40%;
}

.logoslider-wrapper {
    background-color: #1D5980;
    padding: 0 0 30px 0;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    color: white;
    margin-top: -5px;
}

.logoslider-wrapper > h2 {
    padding-bottom: 1em;
}

.logoslider {
    width: 60%;
    margin-left: auto;
    margin-right: auto;
    font-size: 40px;
    display: block;
    float: right;
    color: white;
    align-content: center;
}

.logoslider-container {
    padding-left: 4em;
}

.logoslider div {
    align-content: center;
    text-align: center;
    display: inline-block;
    margin-left: 50px;
}

#landing-text {
    float: left;
    color: white;
    margin-left: 5%;
    width: 40%;
    vertical-align: center;
    display: inline-block;
    padding-bottom: 2em;
}

#landing-text h1 {
    width: 80%;
    justify-content: end;
    padding-bottom: 0.5em;
}

#landing-text p {
    text-align: left;
    width: 80%;
    padding-left: 5%;
    padding-bottom: 1.5em;
}

#landing-text a {
    margin: 0 5%;
    background-color: #1D5980;
}

#landing-image {
    overflow: hidden;
    min-height: 300px;
    padding: 50px;
    max-height: 700px;
}

#landing-image img {
    width: 100%;
    float: right;
    max-width: 58em;
}

/*services*/
.services-wrapper {
    background-color: white;
    text-align: center;
}

.services-wrapper h1 {
    margin-left: 0;
}

.services-wrapper p {
    padding: 1em;
}

.services-wrapper ul {
    padding-bottom: 1em;
}

.services-wrapper ul li:before {
    content: '\2713';
}

.flex-container {
    display: inline-flex;
    flex-wrap: wrap;
}

.flex-container > div {
    background-color: #FFF;
    width: 24%;
    margin: 10px auto 10px auto;
    font-size: 30px;
}

.flex-container > div > h2 {
    padding-bottom: 5px;
    max-width: 1167px;
    margin-left: 0;
}

.flex-container > div > div {
    padding: 0 20px 20px 20px;
    font-size: 15px;
    width: 80%;
    margin: auto;
    text-align: justify;

}

.flex-container > div > img {
    width: 40%;
    margin: auto;
    display: block;
    max-width: 200px;
}

.full-width {
    width: 100%
}

/*client list*/
.clientGrid {
    color: white;
    margin: 0;
    padding-bottom: 1em;
}

.clientGrid h2, p {
    margin: 0;
    text-align: center;

}

.clientGrid img {
    width: 33%;
    max-height: 100px;
    object-fit: contain;
    height: auto;
    top: 0;
    bottom: 0;
    margin: auto;
    vertical-align: middle;

}

.clientGrid p {
    padding: 10px 0 15px 0;
}

.centerWrapper600px {
    margin: auto;
    max-width: 600px;
}

.websites {

}

.websites h1 {
    padding: 1em 0;
    margin: 0;
    text-align: center
}

.websites h2 {
    padding: 1em 0;
    margin: 0;
    color: WHITE;
}

.achievements h1 {
    text-align: center;
    margin: 0;
    padding-bottom: 1em;
}

.portfolio-container {
    display: inline-flex;
    flex-wrap: wrap;
    width: 100%;
    margin: 0;
}

.portfolio-container > div {
    /*background-color: #1D5980;*/
    background-color: #1D5980;
    fill-opacity: 0.8;
    width: 30%;
    margin: 10px auto 10px auto;
    font-size: 30px;
    border-radius: 4px;
    padding: 1%;

}

.portfolio-container > div:hover {
    /*background-color: #1D5980;*/
    background-color: rgba(0, 0, 0, 0.5);
    margin: 10px auto 10px auto;
    font-size: 30px;
    border-radius: 4px;
    padding: 1%;

}

.portfolio-container > div > h2 {
    padding-bottom: 5px;
    margin-left: 0;
    max-width: 1167px;
}

.portfolio-container > div > p {
    padding: 0 10px 10px 0;
    font-size: 15px;
    width: 100%;
    margin: auto;
    text-align: justify;

}

.portfolio-container > div > a > img {
    width: 100%;
}

.portfolio-container > div > img {
    width: 100%;
    margin: auto;
    display: block;

}

.row-container {
    display: inline-flex;
    width: 100%;
    flex-wrap: wrap;
}

.row-container > div {
    /*width: 100%;*/
    width: 30%;
    display: block;
    vertical-align: top;
    padding: 1.5%;
}

.double > div {
    width: 45%;
}

.business-cases > div > h1 {
    margin: 0;
}

.business-cases > div {
    margin: auto;
    padding: 1em 3em;
}

.business-cases li {
    list-style-type: none;
}

.business-cases > div > p {
    text-align: justify;
}

.center-header > h1 {
    text-align: center;
    margin: 0;
}

.row-container h2 {
    text-align: center;
    margin: 0
}

.row-container p {
    text-align: left;
}

.row-container > div > img {
    width: 40%;
    margin: auto;
    display: block;
    max-width: 200px;
}

.grid-container > h1 {
    text-align: center;
    padding-left: 0;
    margin: 0
}

.item-container {
    padding: 0 0;
    text-align: center;
    width: 100%;
}

.item-cards > div {
    background-color: darkslategrey;
    width: 23%;
    margin: 10px 10px 10px auto;
    border-radius: 4px;
    display: inline-block;
    top: 0;
    bottom: 0;
    vertical-align: top;
}

.item-cards > div > h2 {
    padding-bottom: 4px;
}

.item-cards > div > h3 {
    padding-left: 15px;
    padding-bottom: 5px;
}

.logo-item {
    display: inline-block;

}

.logo-item > div {
    display: inline-block;
    width: 30%;
    padding: 0;
}

.logo-item > div > img {
    max-width: 5em;
    margin: 0.5em;
    max-height: 5em;
}

.card-description {
    background-color: white;
    color: #1D5980;
}

.card-description > div {
    padding: 10px;
}

.card-links > a {
    padding: 3px
}

.background-wrapper {
    width: 100%;
}

.white {
    color: white;
}

.background-white {
    background-color: white;
    color: black;
}

.grey {
    background-color: darkslategrey;
}

.grey:hover {
    background-color: #1D5980;
    border-color: #58130e;
}

.drop-shadow {
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
    transition: all 0.3s cubic-bezier(.25, .8, .25, 1);
}

.drop-shadow:hover {
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);

}

/*footer*/
.footer {
    right: 0;
    bottom: 0;
    left: 0;
    padding: 1rem;
    background-color: #FFFFFF;
    color: white;
    text-align: center;
}

.footer > div {
    padding: 0.5em 0;
}

.footer > div > a {
    padding: 5px;
}

/*404*/
.noref {
    padding: auto;

}

.parallax {
    background: no-repeat fixed;
    background-size: cover;
}

/*make landing responsive*/
@media screen and (max-width: 900px) {
    #landing-text {
        float: none;
        margin-right: 0;
        width: auto;
        border: 0;
        padding-bottom: 0;

    }

    #landing-image {
        min-height: 150px;
    }

    .flex-container > div {
        width: 48%;
    }

    .portfolio-container > div {
        width: 45%;
    }

    .item-cards > div {
        width: 46%;
    }

    .row-container > div {
        width: 100%;
    }

}

@media screen and (max-width: 600px) {
    .portfolio-container > div {
        width: 90%;
    }

    .item-cards > div {
        width: 90%;
    }

    .flex-container > div {
        width: 90%;
    }
}

.glitch {
    font-size: 100px;
    position: relative;
    width: 400px;
    margin: 0 auto;
}

.read-more-button {
    color: #4D429F;
}

.service-image {
    height: 150px;
    padding: 1em;
    max-width: 250px;

}

.internal-button {
    display: block;
    margin: auto;
    padding: 1em;
}

.hes-header {
    padding-top: 0.9em;
    margin-left: 0.5em
}

.hes-header-container {
    height: 126px
}


@media (max-width: 468px) {
    .hes-header {
        font-size: 1.4em;
        font-size: 5vw;
        padding-top: 1.25em;
    }

    .hes-logo {
        max-width: 6em;
    }

    .header {
        top: 105px
    }

    .hes-header-container {
        height: 105px
    }

    body {
        padding-top: 164px;
    }
}

.red-hover:hover {
    background-color: #EE2748 !important;
}
